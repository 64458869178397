<template>
  <div ref="lottieContainer" :style="style" />
</template>

<script>
import lottie from 'lottie-web'

export default {
  name: 'Lottie',

  props: {
    /**
     * http://airbnb.io/lottie/#/web?id=usage
     */
    options: {
      type: Object,
      required: true,
    },
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      style: {
        overflow: 'hidden',
      },
    }
  },

  /**
   * Mounted
   */
  mounted() {
    this.anim = lottie.loadAnimation({
      container: this.$refs.lottieContainer,
      renderer: 'svg',
      loop: this.options.loop !== false,
      autoplay: this.options.autoplay !== false,
      animationData: this.options.animationData,
    })
    this.$emit('animCreated', this.anim)
  },
}
</script>
