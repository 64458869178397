<template>
  <section>
    <h2>Je aanvraag is goed afgerond</h2>
    <p class="my-3">
      Nu zijn wij aan zet. Ons team gaat direct voor je aan de slag om de beste
      match te vinden. Binnen 3 werkdagen ontvang je de offertes van onze
      notarissen in je mailbox. Zo kan jij een weloverwogen keuze maken voor de
      beste kwaliteit en de beste prijs.
    </p>
    <t-btn-link
      id="backToHomepage"
      class="t-btn--outlined t-btn--x-large"
      to="/"
      color="primary"
    >
      Terug naar de homepagina
    </t-btn-link>
  </section>
</template>

<script>
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'

export default {
  name: 'LeadConfirmationFormStep',

  extends: AbstractFormStep,
}
</script>
