<template>
  <div>
    <LeadSubmitFormStep :progress-value="100" />
  </div>
</template>

<script>
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import LeadSubmitFormStep from '~/components/form/steps/leadSubmit/LeadSubmitFormStep'

export default {
  name: 'LeadSubmitPage',

  components: {
    LeadSubmitFormStep,
  },

  extends: AbstractFormPage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Aanvraag aan het versturen',
      path: '/offertes-aanvragen/versturen',
      checkoutStep: 2,
    }
  },
}
</script>
