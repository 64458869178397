<template>
  <div class="lead-submit">
    <Lottie :options="loadingAnimationOptions" class="lead-submit__loading" />
  </div>
</template>

<script>
import Lottie from 'chimera/all/components/Lottie'
import LeadSubmitFormPart from 'chimera/all/components/form/parts/LeadSubmitFormPart'

export const field = 'submit'
export default {
  name: 'DelayedLeadSubmitFormPart',

  components: {
    Lottie,
  },

  extends: LeadSubmitFormPart,

  props: {
    delay: {
      type: Number,
      default: 2000,
    },

    animation: {
      type: Object,
      default: () => {},
    },
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      startTimeInMs: undefined,
      loadingAnimationOptions: {
        animationData: this.animation,
      },
    }
  },

  /**
   * On mount
   */
  mounted() {
    this.initialiseLeadPost()
  },

  methods: {
    /**
     */
    initialiseLeadPost() {
      this.setStartTime()
      this.postLead(this.formattedLead)
    },

    /**
     */
    onFinallyLeadPost() {
      const delay = this.calculateDelay(this.startTimeInMs, this.delay)
      this.showResultsWithDelay(delay)
      this.resetStartTime()
    },

    /**
     * Calculate difference based on `startTimeInMs` and current time in ms.
     *
     * @param {number} start - Start time in milliseconds
     * @param {number} delay - Delay milliseconds
     * @returns {number} 0 as absolute minimum
     */
    calculateDelay(start, delay) {
      if (!start) {
        return 0
      }

      const currentTimeInMs = new Date().getTime()
      return Math.max(0, delay - (currentTimeInMs - start))
    },

    /**
     * Emit the results after a artificial delay.
     *
     * @param {number} ms
     */
    showResultsWithDelay(ms) {
      setTimeout(() => {
        this.emitResult(this.value)
      }, ms)
    },

    /**
     * @returns {number}
     */
    setStartTime() {
      this.startTimeInMs = new Date().getTime()
      return this.startTimeInMs
    },

    /**
     * @returns {number}
     */
    resetStartTime() {
      this.startTimeInMs = undefined
      return this.startTimeInMs
    },
  },
}
</script>
