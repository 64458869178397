<template>
  <DelayedLeadSubmitFormPart
    :animation="animationLoading"
    @result="transition"
  />
</template>

<script>
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import DelayedLeadSubmitFormPart from 'chimera/all/components/form/parts/DelayedLeadSubmitFormPart'
import ErrorPage from 'chimera/all/themes/waffle/page/ErrorPage'
import LeadCompletedPage from '~/pages/offertes-aanvragen/aanvraag-afgerond'
import animationLoading from '~/assets/animations/loading.json'

export default {
  name: 'LeadSubmitFormStep',

  components: {
    DelayedLeadSubmitFormPart,
  },

  extends: AbstractFormStep,

  data: () => ({
    animationLoading,
  }),

  methods: {
    /**
     * @param {object}  result
     * @param {boolean} result.isValid
     */
    transition({ isValid }) {
      if (isValid) {
        this.routeTo(LeadCompletedPage)
      } else {
        this.routeTo(ErrorPage)
      }
    },
  },
}
</script>
